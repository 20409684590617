const organization_details = {
    page_title: '組織詳情',
    delete_confirmation: '刪除後，所有成員將失去在這個組織中的成員資格和角色。此操作無法撤銷。',
    organization_id: '組織編號',
    settings_description: '組織代表可以訪問您的應用程序的團隊、業務客戶和合作夥伴公司。',
    name_placeholder: '組織名稱，不需要是唯一的。',
    description_placeholder: '組織的描述。',
    member: '成員',
    member_other: '成員',
    add_members_to_organization: '將成員添加到 {{name}} 組織',
    add_members_to_organization_description: '通過搜索名稱、電子郵件、電話或用戶ID來查找適合的用戶。現有成員不會顯示在搜索結果中。',
    add_with_organization_role: '添加組織角色',
    user: '用戶',
    authorize_to_roles: '授權 {{name}} 訪問以下角色：',
    edit_organization_roles: '編輯組織角色',
    edit_organization_roles_of_user: '編輯 {{name}} 的組織角色',
    remove_user_from_organization: '從組織中刪除用戶',
    remove_user_from_organization_description: '刪除後，用戶將失去在這個組織中的成員資格和角色。此操作無法撤銷。',
    search_user_placeholder: '按名稱、電子郵件、電話或用戶ID搜尋',
    at_least_one_user: '至少需要一個用戶。',
    /** UNTRANSLATED */
    custom_data: 'Custom data',
    /** UNTRANSLATED */
    custom_data_tip: 'Custom data is a JSON object that can be used to store additional data associated with the organization.',
    /** UNTRANSLATED */
    invalid_json_object: 'Invalid JSON object.',
};
export default Object.freeze(organization_details);
