const invitation = {
    /** UNTRANSLATED */
    find_your_tenants: 'Find your tenants',
    /** UNTRANSLATED */
    find_tenants_description: 'Your email address may already be registered with multiple tenants. You can choose to join the existing ones or continue create a new one.',
    /** UNTRANSLATED */
    create_new_tenant: 'Create a new tenant',
    /** UNTRANSLATED */
    email_not_match_title: 'You are currently signed in as\n{{email}}',
    /** UNTRANSLATED */
    email_not_match_description: 'Please sign in with the correct account to accept the invitation and become a member of the organization.',
    /** UNTRANSLATED */
    switch_account: 'Sign in to another account',
    /** UNTRANSLATED */
    invalid_invitation_status: 'Invalid invitation. Please contact the administrator and try again.',
    /** UNTRANSLATED */
    invitation_not_found: 'Invitation not found. Please contact the administrator.',
};
export default Object.freeze(invitation);
