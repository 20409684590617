const organization_details = {
    page_title: 'Detalhes da organização',
    delete_confirmation: 'Uma vez eliminada, todos os membros perderão a sua adesão e funções nesta organização. Esta ação não pode ser desfeita.',
    organization_id: 'ID da organização',
    settings_description: 'As organizações representam as equipas, clientes empresariais e empresas parceiras que podem aceder às suas aplicações.',
    name_placeholder: 'O nome da organização, não é necessário ser único.',
    description_placeholder: 'Uma descrição da organização.',
    member: 'Membro',
    member_other: 'Membros',
    add_members_to_organization: 'Adicionar membros à organização {{name}}',
    add_members_to_organization_description: 'Encontre utilizadores adequados pesquisando por nome, email, telefone ou ID de utilizador. Os membros existentes não são mostrados nos resultados da pesquisa.',
    add_with_organization_role: 'Adicionar com função(ões) de organização',
    user: 'Utilizador',
    authorize_to_roles: 'Autorizar {{name}} a aceder às seguintes funções:',
    edit_organization_roles: 'Editar funções da organização',
    edit_organization_roles_of_user: 'Editar funções da organização de {{name}}',
    remove_user_from_organization: 'Remover utilizador da organização',
    remove_user_from_organization_description: 'Uma vez removido, o utilizador perderá a sua adesão e funções nesta organização. Esta ação não pode ser desfeita.',
    search_user_placeholder: 'Pesquisar por nome, email, telefone ou ID de utilizador',
    at_least_one_user: 'Pelo menos um utilizador é necessário.',
    /** UNTRANSLATED */
    custom_data: 'Custom data',
    /** UNTRANSLATED */
    custom_data_tip: 'Custom data is a JSON object that can be used to store additional data associated with the organization.',
    /** UNTRANSLATED */
    invalid_json_object: 'Invalid JSON object.',
};
export default Object.freeze(organization_details);
