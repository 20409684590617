const organization_details = {
    page_title: "Dettagli dell'organizzazione",
    delete_confirmation: "Una volta eliminato, tutti i membri perderanno la loro iscrizione e ruoli in questa organizzazione. Quest'azione non può essere annullata.",
    organization_id: 'ID organizzazione',
    settings_description: 'Le organizzazioni rappresentano i team, i clienti aziendali e le società partner che possono accedere alle tue applicazioni.',
    name_placeholder: "Il nome dell'organizzazione, non è necessario che sia univoco.",
    description_placeholder: "Una descrizione dell'organizzazione.",
    member: 'Membro',
    member_other: 'Membri',
    add_members_to_organization: "Aggiungi membri all'organizzazione {{name}}",
    add_members_to_organization_description: 'Trova gli utenti appropriati cercando per nome, email, telefono o ID utente. Gli utenti già esistenti non vengono mostrati nei risultati della ricerca.',
    add_with_organization_role: "Aggiungi con ruolo(i) dell'organizzazione",
    user: 'Utente',
    authorize_to_roles: 'Autorizza {{name}} ad accedere ai seguenti ruoli:',
    edit_organization_roles: "Modifica ruoli dell'organizzazione",
    edit_organization_roles_of_user: "Modifica ruoli dell'organizzazione di {{name}}",
    remove_user_from_organization: "Rimuovi utente dall'organizzazione",
    remove_user_from_organization_description: "Una volta rimosso, l'utente perderà la sua iscrizione e i ruoli in questa organizzazione. Quest'azione non può essere annullata.",
    search_user_placeholder: 'Cerca per nome, email, telefono o ID utente',
    at_least_one_user: 'È richiesto almeno un utente.',
    /** UNTRANSLATED */
    custom_data: 'Custom data',
    /** UNTRANSLATED */
    custom_data_tip: 'Custom data is a JSON object that can be used to store additional data associated with the organization.',
    /** UNTRANSLATED */
    invalid_json_object: 'Invalid JSON object.',
};
export default Object.freeze(organization_details);
